<template>
    <div class="website">
        <div class="wrap">
            <h1 class="title wow animated fadeInDown">
                <span class="gradient">{{$t('common.contact')}}</span>
            </h1>

            <div class="text_box wow animated fadeInUp">
                <p class="item">
                    {{$t('website.contact.text1')}}
                </p>
                <p class="item">
                    {{$t('website.contact.text2',{version:vNum})}}
                </p>
            </div>

            <!-- 隐藏联系方式 -->
            <!-- <div class="code_box wow animated fadeInUp"> -->
                <!-- <div class="item"> -->
                    <!-- <div class="icon">
                        <img src="@/assets/images/official/code1.jpg" alt="Lightning Market Making System - Business">
                    </div> -->
                    <!-- <div class="bottom_box">
                        <p>{{$t('website.contact.business')}}</p> -->
                        <!-- <p class="number">13155477337</p> -->
                        <!-- <p class="number">Telegram:
                            <span class="telegram" @click="jumpTelegram('https://t.me/jessie7337')">@jessie7337</span>
                        </p> -->
                        <!-- <p class="number">WeChat: Jessie7337</p > -->
                    <!-- </div>
                </div> -->
                <!-- <div class="item"> -->
                    <!-- <div class="icon">
                        <img src="@/assets/images/official/code2.jpg" alt="Lightning Market Making System - Customer Service">
                    </div> -->
                    <!-- <div class="bottom_box">
                        <p>{{$t('website.contact.service')}}</p> -->
                        <!-- <p class="number">13155477337</p> -->
                        <!-- <p class="number">Telegram:
                            <span class="telegram" @click="jumpTelegram('https://t.me/Light7665')">@Light7665</span>
                        </p> -->
                        <!-- <p class="number">WeChat: Light7665</p > -->
                    <!-- </div> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
    import { WOW } from 'wowjs'
    import { mapGetters } from "vuex"
    export default {
        mounted() {
            this.wow();
        },
        computed: {
            ...mapGetters(['version']),
            vNum() {
                var versionArr = this.version.split('.')
                return versionArr[0] + '.' + versionArr[1]
            }
        },
        methods: {
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 50,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
            jumpTelegram(path) {
                window.location.href = path
            },
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './homeContact.styl';
    @import '~@/assets/style/frames.styl';
</style>